<template>
  <div>
    <v-dialog
      v-model="modal"
      max-width="300px"
      @click:outside="$emit('close')"
    >
      <v-card>

        <v-card-title primary-title>
          {{$t('s-A1V_Ga1VdOkD6JDcH4Z')}}
        </v-card-title>

        <div
          elevation="0"
          class="text-center ma-4 zoom30"
          max-width="300"
        >
          <v-card-text>

            <v-btn
              v-if="['US', 'CA', 'GB', 'AU', 'DE', 'FR', 'JP', 'CH', 'NL', 'SE', 'NO', 'FI', 'DK', 'BE', 'LU'].includes($root.country) && $root.lang !== 'ru'"
              block
              color="primary"
              class="align-center my-3"
              @click.prevent="openPay('stripe')"
            >
              <v-icon left>
                mdi-credit-card-outline
              </v-icon>
              MasterCard, Visa
            </v-btn>

            <v-btn
              v-if="$root.country !== 'RU' && $root.lang !== 'ru' && $root.country !== 'US' && $root.country !== 'CA'"
              block
              color="primary"
              class="align-center my-3"
              @click.prevent="openPay('unitpay')"
            >
              <v-icon left>
                mdi-credit-card-outline
              </v-icon>
              MasterCard, Visa
            </v-btn>

            <!-- <v-btn
              v-if="$root.country !== 'RU' && $root.lang !== 'ru'"
              block
              color="primary"
              class="align-center my-3"
              @click.prevent="openPay('stripe')"
            >
              <v-icon left>
                mdi-credit-card-outline
              </v-icon>
              MasterCard, Visa
            </v-btn> -->

            <!-- ЧЕРЕЗ PAYOP -->
            <!-- <v-btn
              block
              color="primary"
              class="align-center my-3"
              @click.prevent="openPay('payop')"
            >
              <v-icon left>
                mdi-credit-card-outline
              </v-icon>
              MasterCard, Visa
            </v-btn>

            <v-btn
              block
              color="primary"
              class="align-center my-3"
              @click.prevent="openPay('crypto')"
            >
              <v-icon left>
                mdi-bitcoin
              </v-icon>
              {{$t('H-lSAnudMozz5x58Vh24U')}}
            </v-btn> -->

            <!-- <p>
              💰 <a
                target="_blank"
                rel="nofollow noreferrer noopener"
                :href="`https://localbitcoins.com/country/${$root.country}?ch=1h0kq`"
              >{{$t('k1Hw2ifyKaiFIfUVVyrmm')}} &rarr;</a>
            </p> -->

          </v-card-text>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            @click="modal = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '@/config'

export default {
  props: ['modal', 'days', 'price', 'tariffId'],

  computed: {
    ...mapGetters(['user'])
  },

  methods: {
    openPay (payType) {
      this.$gtm.trackEvent({
        event: 'PAY_BUTTON_CLICK'
      })
      const url = `${config.data.apiEndpoint}/payment/loading/${this.user.id}:${this.tariffId}:${this.$root.country}:${payType}`
      window.open(url, '_blank')

      return false

    }
  }
}
</script>

<style scoped>
.zoom30 {
  transform: scale(1.1);
}
.zoom60 {
  transform: scale(0.9);
}
del {
  position: relative;
  color: #000;
  text-decoration: none;
}
del:before {
  content: "";
  border-bottom: 2px solid red;
  position: absolute;
  top: 0;
  left: -10%;
  width: 120%;
  height: 50%;
  transform: rotate(-4deg);
}
</style>
