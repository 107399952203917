<template>
  <v-container>
    <v-container>
      <h1>{{$t('1oDFDUNU8DYDEw2PrswIj')}}</h1>
    </v-container>

    <VueRecaptcha
      :loadRecaptchaScript="true"
      :sitekey="$root.recaptchaSitekey"
      ref="recaptcha"
      @verify="checkPromocode"
      @expired="onCaptchaExpired"
      size="invisible"
    >
    </VueRecaptcha>

    <v-container>

      <template v-if="isLogin">
        <p>{{$t('j3cgDgcwEpN00MtSTRTmh')}}
          <template v-if="Object.keys(userReports).length">
            <v-chip
              v-for="m in userReports"
              :key="m"
              class="ma-1"
              color="primary"
            >
              {{ $moment(m).format('MMMM YYYY') }}
            </v-chip>
          </template>
          <template v-else>
            <v-chip
              class="ma-1"
              color="error"
            >
              {{ $t('oE4hBgF_V3JWIlOb_o1er') }}
            </v-chip>
          </template>

        </p>
      </template>

      <v-container class="d-flex justify-center flex-wrap my-5">

        <v-card
          v-for="(tariff, i) in $root.tariffs"
          :key="i"
          class="text-center ma-4"
          :class="[{'elevation-15': i===2},{'zoom30': i===1},{'zoom15': i===2},{'zoom60': i===3}]"
          max-width="230"
        >
          <v-card-text>
            <div>{{$t('KggrHnYm0-EeUW6mcf4Pb')}}</div>

            <p
              class="text-uppercase font-weight-black title"
              v-if="i === 3"
            >{{ $t('-rtwfDqnyTux1sCoq9UQD') }} </p>
            <p
              v-else
              class="subtitle-1 text-uppercase text--primary"
            >
              {{tariff.months}}
              <template v-if="i !== 0">
                {{$t('GaOXHLBvLOfLpfk6ywNV1')}}
              </template>
              <template v-else>
                {{ $t('JUqmJuOdRK1cXLkJJCKCg') }}
              </template>
            </p>

            <div>
              <span
                v-if="i !== 3"
                class="display-1 text-uppercase text--primary font-weight-bold"
              >
                <template v-if="$root.country == 'RU' || $root.lang == 'ru'">
                  {{ (tariff.ru_price / tariff.months).toFixed(0) }} ₽
                </template>
                <template v-else>
                  ${{ (tariff.price / tariff.months).toFixed(2) }}
                </template>
              </span>
              <span
                v-else
                class="display-1 text-uppercase text--primary font-weight-bold"
              >$0</span>
              <span>/{{$t('0b6sxs-o8iyCo-1_3uN-e')}}</span>
            </div>

            <div
              v-if="i === 1 || i === 2"
              class="text-uppercase text-h5"
            ><del>
                <template v-if="$root.country == 'RU' || $root.lang == 'ru'">
                  {{ ($root.tariffs[0].ru_price / $root.tariffs[0].months).toFixed(0) }} ₽
                </template>
                <template v-else>
                  ${{ ($root.tariffs[0].price / $root.tariffs[0].months).toFixed(2) }}
                </template>
              </del></div>

            <v-chip
              @click.prevent="openSelectPay(tariff,i)"
              v-if="i === 0"
              class="ma-2"
              color="grey"
              text-color="white"
            >
              {{$t('0hYVsBHJcTPBL9ulbR0fD')}}
              <v-icon right>mdi-emoticon-sad</v-icon>
            </v-chip>

            <v-chip
              @click.prevent="openSelectPay(tariff,i)"
              v-if="i === 1 || i === 2"
              class="ma-2"
              :class="[{'highlight': i===1}]"
              :color="i===1 ? 'green' : 'blue'"
              text-color="white"
            >
              {{$t('ONkYakuXCLvR4SfemVFHC')}} <b class="ml-1">
                {{
              100 - Math.ceil(
               (tariff.price / tariff.months)
                /
                ($root.tariffs[0].price / $root.tariffs[0].months)
                * 100
                )
               }}%&nbsp;
                <template v-if="$root.country == 'RU' || $root.lang == 'ru'">
                  (-{{ 
                  (
                      (
                        ($root.tariffs[0].ru_price / $root.tariffs[0].months).toFixed(2) * tariff.months
                      ) - (
                        (tariff.ru_price / tariff.months).toFixed(2) * tariff.months
                      )
                  ).toFixed(0)
                }} ₽)
                </template>
                <template v-else>
                  (-${{ 
                  (
                      (
                        ($root.tariffs[0].price / $root.tariffs[0].months).toFixed(2) * tariff.months
                      ) - (
                        (tariff.price / tariff.months).toFixed(2) * tariff.months
                      )
                  ).toFixed(0)
                }})
                </template>
              </b>
              <v-icon right>mdi-star</v-icon>
            </v-chip>

            <div class="text--primary text-left pt-3">
              <ul class="features">
                <li
                  v-for="(feature, i) in tariff.features"
                  :key="i"
                >
                  {{ feature }}
                </li>
              </ul>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex pb-5">
            <v-spacer />

            <v-btn
              v-if="i !== 3"
              color="primary"
              class="align-center"
              @click.prevent="openSelectPay(tariff,i)"
            >
              <template v-if="$root.country == 'RU' || $root.lang == 'ru'">
                {{$t('l_KNK8CoQbqOvoeVH3glt')}} {{ tariff.ru_price }} ₽
              </template>
              <template v-else>
                {{$t('l_KNK8CoQbqOvoeVH3glt')}} ${{ tariff.price }}
              </template>
            </v-btn>

            <v-spacer />
          </v-card-actions>
        </v-card>

      </v-container>

      <template v-if="isLogin">
        <div class="pb-10 text-center">

          <v-dialog
            v-model="promocodeDialog"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                text
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('rMozpqXkLxM2f7NCdd-GB') }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                {{ $t('cL8Dvhjq0UQ5TEYHhtc3H') }}
              </v-card-title>

              <v-card-text>
                <v-text-field
                  v-model="promocode"
                  :disabled="loading"
                  clearable
                  text
                  solo-inverted
                  hide-details="auto"
                  prepend-inner-icon="mdi-brightness-percent"
                  :label="$t('EZs2LPiZl5p64gHlc6WQ7')"
                ></v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  @click="promocodeDialog = false"
                  :disabled="loading"
                >
                  {{ $t('MCJiwJsAQ4FAW0bVdt0X_') }}
                </v-btn>
                <v-btn
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="applyPromocodeClick()"
                >
                  {{ $t('VG71NFIiYjKaXJlUvcYeP') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </div>
      </template>

      <v-container>

        <p>{{$t('5IDXwCBQgufcZarduOwY7')}}</p>
        <p>{{$t('PI2C5sDiwowyrpTB5WVl9')}}</p>
        <p>{{$t('n9SimizYHoa8-AyRSQHMX')}}</p>
        <p>{{ $t('3G6GMG3P8GZH1kK64dxPq') }}</p>

      </v-container>
    </v-container>

    <SelectPayModal
      :modal="selectPayShow"
      :price="selectPrice"
      :days="selectDays"
      :tariffId="selectTariffId"
      @close="selectPayShow = false"
    />

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueRecaptcha from 'vue-recaptcha'
import SelectPayModal from '@/components/SelectPayModal'

export default {
  components: {
    SelectPayModal, VueRecaptcha
  },
  data () {
    return {
      selectPayShow: false,
      selectDays: null,
      selectPrice: null,
      selectTariffId: null,

      promocodeDialog: false,
      promocode: null
    }
  },

  computed: {
    ...mapGetters(['userReports', 'user', 'isLogin', 'loading']),

    tariffs () {
      return this.$root.country === 'RU' ? this.$root.tariffs.RUB : this.$root.tariffs.USD
    }

  },

  async mounted () {
    if (this.isLogin) {
      // обновляем баланс при открытии страницы, чтобы не было бага, когда не сразу обновляется
      await this.$store.dispatch('autoLogin')
    }
  },

  methods: {
    ...mapActions(['applyPromocode', 'setLoading']),

    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },

    async checkPromocode (recaptchaToken) {
      await this.applyPromocode({ promocode: this.promocode, recaptchaToken })
      this.promocode = null
      this.promocodeDialog = false
    },

    applyPromocodeClick () {
      if (this.promocode) {
        this.setLoading(true)
        this.$refs.recaptcha.reset()
        this.$refs.recaptcha.execute()
      }
    },

    openSelectPay (tariff, id) {
      // * если юзер в системе
      if (this.isLogin) {
        this.selectDays = tariff.days
        this.selectPrice = tariff.price
        this.selectTariffId = id
        this.selectPayShow = true
      } else {
        // * если не залогинен, то отправляем на регистрацию
        this.$router.push({ name: 'reg' })
      }
    }

  }
}
</script>

<style scoped>
.zoom15 {
  opacity: 0.8;
  transform: scale(0.9);
}
.zoom30 {
  transform: scale(1.1);
}
.zoom60 {
  transform: scale(0.8);
  opacity: 0.6;
}
del {
  position: relative;
  text-decoration: none;
}
del:before {
  content: "";
  border-bottom: 2px solid red;
  position: absolute;
  top: 0;
  left: -10%;
  width: 120%;
  height: 50%;
  transform: rotate(-4deg);
}

.highlight {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  overflow: hidden;
  -webkit-transition: ease 0.3s;
  transition: ease 0.3s;
}

.highlight:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  -webkit-transition: ease 0.3s;
  transition: ease 0.3s;
}

.highlight:after {
  content: "";
  display: block;
  width: 30px;
  height: 300px;
  margin-left: 60px;
  background: rgba(255, 255, 255, 0.6);
  filter: blur(5px);
  position: absolute;
  left: -40px;
  top: -150px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-animation-name: slideme;
  animation-name: slideme;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes slideme {
  0% {
    left: -30px;
    margin-left: 0px;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}

.features {
  padding-left: 10px;
}
.features li {
  padding-left: 0;
  list-style: none;
}
</style>
